'use client'

import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useColorScheme } from '@mui/material/styles'
import { useMediaQuery, Button, Box, Typography } from '@mui/material'

// Types
import type { LogoProps } from '@/types/components/atoms/logo'

// Components
const TlichoIconAtom = dynamic(() => import('@/components/atoms/icons/tlicho'))

const LogoAtom = (props: LogoProps) => {
	// Props
	const { mode } = props

	// Mode
	const colorScheme = useColorScheme()
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const themeMode = colorScheme.mode == 'system' ? (prefersDarkMode ? 'dark' : 'light') : colorScheme.mode

	return (
		<Link href="/">
			<Button
				color="inherit"
				startIcon={<TlichoIconAtom />}
				sx={{
					textTransform: 'inherit',
					color: mode === 'dark' || themeMode === 'dark' ? 'common.white' : 'primary.main',
					'& .MuiButton-icon': {
						mr: 2.125,
						'& .MuiSvgIcon-root': { fontSize: { xs: 36, md: 64 } }
					}
				}}
			>
				<Box display="flex" flexDirection="column">
					<Typography component="span" textAlign="start" color="primary.dark" fontSize={{ md: 20 }} fontWeight={700} lineHeight={1}>
						Tłı̨chǫ
					</Typography>

					<Typography component="span" textAlign="start" color="primary.dark" mt={0.5} fontSize={{ md: 20 }} lineHeight={1} letterSpacing={0.4}>
						learning
					</Typography>
				</Box>
			</Button>
		</Link>
	)
}

export default LogoAtom
